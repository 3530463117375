import { FocusEvent } from 'react'
import { Location } from 'react-router-dom'
import {
  SURGERYFIT_ACCESS_TOKEN, SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE, SURGERYFIT_REFRESH_TOKEN,
  userRoleList, vendorList,
} from './constants'

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export const setToken = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem(SURGERYFIT_ACCESS_TOKEN, accessToken)
  sessionStorage.setItem(SURGERYFIT_REFRESH_TOKEN, refreshToken)

  sessionStorage.setItem(
    SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE,
    `${parseJwt(accessToken).exp}`,
  )
}

export function removeToken() {
  sessionStorage.removeItem(SURGERYFIT_ACCESS_TOKEN)
  sessionStorage.removeItem(SURGERYFIT_REFRESH_TOKEN)
  sessionStorage.removeItem(SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE)
}

// input 태그에 포커스가 갔을 때
export const onFocus = (e: FocusEvent<HTMLInputElement>) => {
  const elem = e.target.parentElement
  if (elem) {
    elem.className = 'focused'
  }
}

// input 태그에서 포커스가 나갔을 때
export const onBlur = (e: FocusEvent<HTMLInputElement>) => {
  const elem = e.target.parentElement
  if (elem) {
    elem.className = ''
  }
}

export const parseQueryString = (location: Location) => {
  const result: {
    [key: string]: string | undefined,
  } = {}
  const search = location.search.substring(1)

  const list = search.split('&')
  list.forEach((str) => {
    const keyVal = str.split('=')
    const [key, val] = keyVal
    result[key] = decodeURI(val)
  })

  return result
}

export const downloadFile = (url: string) => {
  const downloadLinkElem = document.createElement('a')
  downloadLinkElem.href = url

  document.body.appendChild(downloadLinkElem)
  downloadLinkElem.click()
  document.body.removeChild(downloadLinkElem)
}

export const convertRolecodeToRolestr = (rolecode: number) => {
  for (let i = 0; i < userRoleList.length; i += 1) {
    if (userRoleList[i].key === rolecode) {
      return userRoleList[i].val
    }
  }

  return String(rolecode)
}

export const convertRolestrToRolecode = (rolestr: string) => {
  for (let i = 0; i < userRoleList.length; i += 1) {
    if (userRoleList[i].val === rolestr) {
      return userRoleList[i].key
    }
  }

  return Number(rolestr)
}

export const convertVendorcodeToVendorstr = (vendorcode: number) => {
  for (let i = 0; i < vendorList.length; i += 1) {
    if (vendorList[i].key === vendorcode) {
      return vendorList[i].val
    }
  }

  return String(vendorcode)
}

export const convertVendorstrToVendorcode = (vendorstr: string) => {
  for (let i = 0; i < vendorList.length; i += 1) {
    if (vendorList[i].val === vendorstr) {
      return vendorList[i].key
    }
  }

  return Number(vendorstr)
}
