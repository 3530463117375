import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import HospitalModal from '../../components/HospitalModal'
import Pagination from '../../components/Pagination'
import ShowNoDataTr from '../../components/ShowNoDataTr'
import { orderStatusList, vendorList } from '../../libs/constants'
import {
  convertVendorcodeToVendorstr, convertVendorstrToVendorcode, onBlur, onFocus, parseQueryString,
} from '../../libs/helpers'
import { InjectedRootStoreType } from '../../stores'

function PatientList({ RootStore }: InjectedRootStoreType) {
  const { patientStore } = RootStore!
  const location = useLocation()
  const navigate = useNavigate()

  // page, rows 하나라도 없으면 쿼리스트링 붙여서 리로드
  const {
    page, rows, patientId, patientName, hospitalName,
  } = parseQueryString(location)
  useEffect(() => {
    if (!page || !rows) {
      navigate({
        pathname: '/patient/patients',
        search: '?page=1&rows=10&patientId=&patientName=&hostpialName=',
      })
    }
  }, [page, rows, patientId, patientName, hospitalName])

  // 데이터 가져오기
  useEffect(() => {
    if (page && rows) {
      patientStore.setPage(page ? Number(page) : 1)
      patientStore.setRows(page ? Number(rows) : 10)
      patientStore.setPatientId(patientId || '')
      patientStore.setPatientName(patientName || '')
      patientStore.setHospitalName(hospitalName || '')

      patientStore.fetchList()
    }
  }, [page, rows, patientId, patientName, hospitalName])

  // 검색 state
  const [patientIdInput, setPatientIdInput] = useState('')
  const [patientNameInput, setPatientNameInput] = useState('')
  const [hospitalNameInput, setHospitalNameInput] = useState('')

  // 검색 버튼 클릭 시
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    navigate({
      pathname: '/patient/patients',
      search: `?page=1&rows=${rows}&patientId=${patientIdInput}&patientName=${patientNameInput}&hospitalName=${hospitalNameInput}`,
    })
  }

  // 추가 모달 관련 state
  const [isModal, setIsModal] = useState(false)

  // 추가 모달 띄우기
  const showModal = () => {
    patientStore.initAdd()

    setIsModal(true)
  }

  // 추가 모달 닫기/취소
  const closeModal = () => {
    setIsModal(false)
  }

  // 모달 추가 버튼 클릭 시
  const addPatient = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await patientStore.addData()
    if (result) {
      swal('추가되었습니다.', '', 'info')
      setIsModal(false)
      patientStore.fetchList()
    }
  }

  // 병원 모달 관련 state
  const [isHospitalModal, setIsHospitalModal] = useState(false)

  return (
    <main className="patient-list-page">
      <h1>Patient / Patients</h1>

      <form onSubmit={submitHandler} className="search-bar">
        <label htmlFor="patientIdInput">
          <span>PatientId</span>
          <img alt="" />
          <input
            type="text"
            id="patientIdInput"
            value={patientIdInput}
            onChange={(e) => {
              const { value } = e.target
              if (!Number.isNaN(Number(value))) {
                setPatientIdInput(e.target.value)
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <label htmlFor="patientNameInput">
          <span>PatientName</span>
          <img alt="" />
          <input
            type="text"
            id="patientNameInput"
            value={patientNameInput}
            onChange={(e) => setPatientNameInput(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <label htmlFor="hospitalNameInput">
          <span>HospitalName</span>
          <img alt="" />
          <input
            type="text"
            id="hospitalNameInput"
            value={hospitalNameInput}
            onChange={(e) => setHospitalNameInput(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <button type="submit">&nbsp;</button>
      </form>

      <div className="user-actions text-right">
        <button type="button" className="user-action add" onClick={showModal}>
          <img alt="" />
          환자 추가
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: '7%' }}>PatientId</th>
            <th style={{ width: '14%' }}>PatientName</th>
            <th style={{ width: '14%' }}>HospitalName</th>
            <th style={{ width: '11%' }}>VisitDate</th>
            <th style={{ width: '11%' }}>OperationDate</th>
            <th style={{ width: '19%' }}>Description</th>
            <th style={{ width: '10%' }}>OrderStatus</th>
            <th style={{ width: '7%' }}>Vendor</th>
            <th style={{ width: '7%' }}>Confirmed</th>
          </tr>
        </thead>

        <tbody>
          {patientStore.list.map((patient) => (
            <tr key={patient.PatientUUID}>
              <td>
                <Link to={`/patient/${patient.PatientUUID}/edit`} className="custom-link">{patient.PatientId}</Link>
              </td>
              <td>{patient.PatientName}</td>
              <td>{patient.HospitalName}</td>
              <td>{patient.VisitDate}</td>
              <td>{patient.OperationDate}</td>
              <td>{patient.Description}</td>
              <td>{patient.OrderStatus}</td>
              <td>{patient.Vendor}</td>
              <td>{patient.Confirmed ? 'true' : 'false'}</td>
            </tr>
          ))}

          <ShowNoDataTr list={patientStore.list} colSpan={9} />
        </tbody>
      </table>

      <Pagination />

      {isModal && (
      <div className="add-modal">
        <div className="modal">
          <div className="close">
            <button type="button" onClick={closeModal}>&nbsp;</button>
          </div>

          <div className="head">
            환자 추가
          </div>

          <form onSubmit={addPatient}>
            <div className="form-labels">
              <div className="red">* 표시는 필수항목입니다.</div>

              <label htmlFor="patientNumber">
                <span>
                  PatientNumber
                  <span className="color-red">* </span>
                </span>

                <input
                  type="text"
                  id="patientNumber"
                  value={patientStore.add.PatientNumber}
                  onChange={(e) => patientStore.setAddPatientNumber(e.target.value)}
                  required
                />
              </label>

              <label htmlFor="patientName">
                <span>
                  PatientName
                  <span className="color-red">* </span>
                </span>

                <input
                  type="text"
                  id="patientName"
                  value={patientStore.add.PatientName}
                  onChange={(e) => patientStore.setAddPatientName(e.target.value)}
                  required
                />
              </label>

              <label htmlFor="visitDate">
                <span>
                  VisitDate
                  <span className="color-red">* </span>
                </span>

                <input
                  type="date"
                  id="visitDate"
                  value={patientStore.add.VisitDate}
                  onChange={(e) => patientStore.setAddVisitDate(e.target.value)}
                  required
                />
              </label>

              <label htmlFor="operationDate">
                <span>
                  OperationDate
                  <span className="color-red">* </span>
                </span>

                <input
                  type="date"
                  id="operationDate"
                  value={patientStore.add.OperationDate}
                  onChange={(e) => patientStore.setAddOperationDate(e.target.value)}
                  required
                />
              </label>

              <label htmlFor="vendor">
                <span>
                  Vendor
                  <span className="color-red">* </span>
                </span>

                <select
                  id="vendor"
                  value={convertVendorcodeToVendorstr(patientStore.add.Vendor)}
                  onChange={(e) => patientStore.setAddVendor(
                    convertVendorstrToVendorcode(e.target.value),
                  )}
                >
                  {vendorList.map(({ key, val }) => (
                    <option key={key}>{val}</option>
                  ))}
                </select>
              </label>

              <label htmlFor="description">
                <span>
                  Description
                </span>

                <textarea
                  id="description"
                  value={patientStore.add.Description}
                  onChange={(e) => patientStore.setAddDescription(e.target.value)}
                />
              </label>

              <label htmlFor="orderStatus">
                <span>
                  OrderStatus
                  <span className="color-red">* </span>
                </span>

                <select
                  value={patientStore.add.OrderStatus}
                  onChange={(e) => patientStore.setAddOrderStatus(e.target.value)}
                >
                  {orderStatusList.map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                </select>
              </label>

              <label htmlFor="hospital" onFocus={() => setIsHospitalModal(true)}>
                <span>
                  Hospital
                  <span className="color-red">* </span>
                </span>

                <input
                  type="text"
                  id="hospital"
                  className="read-only with-search"
                  value={patientStore.add.HospitalName}
                  required
                  readOnly
                />

                <button type="button" className="search-button">&nbsp;</button>
              </label>

              <label htmlFor="confirmed">
                <span>
                  Confirmed
                  <span className="color-red">* </span>
                </span>

                <input
                  type="checkbox"
                  id="confirmed"
                  checked={patientStore.add.Confirmed}
                  onChange={(e) => patientStore.setAddConfirmed(e.target.checked)}
                />
              </label>
            </div>

            <div className="bottom">
              <button type="button" onClick={closeModal}>취소</button>
              <button type="submit">추가</button>
            </div>
          </form>
        </div>
      </div>
      )}

      {isHospitalModal && (
        <HospitalModal
          setIsModal={(tf) => setIsHospitalModal(tf)}
          targetIdxSetter={(hospitalId) => patientStore.setAddHospitalID(hospitalId)}
          targetNameSetter={(hName) => patientStore.setAddHospitalName(hName)}
        />
      )}
    </main>
  )
}

export default inject('RootStore')(observer(PatientList))
