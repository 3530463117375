import { AxiosRequestConfig } from 'axios'
import { autorun, observable, runInAction } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'

const hospitalModalStore = observable({
  // 검색 정보
  name: '',

  // 병원 전체 리스트
  fetchedList: [] as Hospital[],

  // 검색 결과 리스트
  list: [] as Hospital[],

  async fetchData() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/hospitals',
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        const { data } = response

        const modified = data.map((obj: {
          hospital_id: string, hospital_name: string, address: string
        }) => ({
          ...obj,
          hospitalId: obj.hospital_id,
          hospitalName: obj.hospital_name,
        }))

        this.setFetchedList(modified)
      } else if (response.status !== 200) {
        swal('데이터 가져오기에 실패하였습니다.', '', 'error')
      }
    } catch {
      swal('데이터 가져오기에 실패하였습니다.', '', 'error')
    }
  },

  initData() {
    this.setName('')
  },

  setName(name: string) {
    this.name = name
  },

  setFetchedList(list: Hospital[]) {
    this.fetchedList = list
  },

  setList(list: Hospital[]) {
    this.list = list
  },
})

// 전체 목록이나 검색 조건 변경 시 보여줄 데이터 변경
autorun(() => {
  const filteredList = hospitalModalStore.fetchedList.filter((hospital) => {
    if (hospitalModalStore.name !== '' && !(hospital.hospitalName.toLowerCase().includes(hospitalModalStore.name.toLowerCase()))) return false

    return true
  })

  runInAction(() => hospitalModalStore.setList(filteredList))
})

export default hospitalModalStore
