import React from 'react'

function ShowNoDataTr({ list, colSpan }: { list: any[], colSpan: number }) {
  if (list.length === 0) {
    return (
      <tr>
        <td colSpan={colSpan} style={{ color: 'gray' }}>데이터 없음</td>
      </tr>
    )
  }

  return null
}

export default ShowNoDataTr
