import { AxiosRequestConfig } from 'axios'
import { autorun, observable, runInAction } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'
import paginationStore from '../components/paginationStore'
import { userRoleList } from '../../libs/constants'

const userStore = observable({
  // 검색 정보
  page: 1,
  rows: 10,
  userId: '' as number | '',
  userName: '',
  name: '',

  // 유저 전체 리스트
  fetchedList: [] as User[],

  // 검색 결과 리스트
  list: [] as User[],

  // 수정 시 사용하는 데이터
  detail: {
    UserId: 0,
    UserName: '',
    FirstName: '',
    LastName: '',
    Active: false,
    Role: userRoleList[0].key,
    HospitalID: 1,
  } as User,

  // 추가 시 사용하는 데이터
  add: {
    UserName: '',
    Password: '',
    FirstName: '',
    LastName: '',
    HospitalID: '',
    Role: userRoleList[0].key,
    Active: false,
  } as User,
  addPasswordCheck: '',
  get isAddPossible() {
    return (
      this.addPasswordCheck.length !== 0 && this.add.Password === this.addPasswordCheck
      && this.add.HospitalID !== ''
    )
  },

  async fetchList() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/users',
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        this.setFetchedList(response.data)
        paginationStore.setQuery(`&userId=${this.userId}&userName=${this.userName || ''}&name=${this.name || ''}`)
      } else {
        swal('데이터 가져오기에 실패하였습니다.', '', 'error')
      }
    } catch {
      swal('데이터 가져오기에 실패하였습니다.', '', 'error')
    }
  },

  async fetchDetailData(userId: number) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/user/${userId}`,
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        this.setDetail(response.data)
      } else {
        throw new Error()
      }
    } catch {
      swal('데이터 가져오기에 실패하였습니다.', '', 'error')
        .then(() => { document.location.href = '/user/users' })
    }
  },

  async addData() {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/users',
      data: this.add,
    }

    try {
      const response = await authedAxios(config)
      if (response.status === 201) {
        return true
      }

      swal('추가에 실패하였습니다.', response.data, 'error')
    } catch (error) {
      swal('추가에 실패하였습니다.', '', 'error')
    }

    return false
  },

  async editData() {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/user/${this.detail.UserId}`,
      data: {
        FirstName: this.detail.FirstName,
        LastName: this.detail.LastName,
        Active: this.detail.Active,
        UserStatus: this.detail.UserStatus,
      },
    }

    const response = await authedAxios(config)
    try {
      if (response.status === 200) {
        return true
      }

      swal('수정에 실패하였습니다.', response.data.msg, 'error')
    } catch (error) {
      swal('수정에 실패하였습니다.', '', 'error')
    }

    return false
  },

  async deleteUser(userId: number) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/user/${userId}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      return true
    }
    return false
  },

  initDetail() {
    this.setDetail({
      UserId: 0,
      UserName: '',
      FirstName: '',
      LastName: '',
      Active: false,
      Role: userRoleList[0].key,
      HospitalID: 1,
    } as User)
  },

  initAdd() {
    this.setAddUserName('')
    this.setAddPassword('')
    this.setAddFirstName('')
    this.setAddLastname('')
    this.setAddHospitalId('')
    this.setAddHospitalName('')
    this.setAddRole(userRoleList[0].key)
    this.setAddActive(false)
    this.setAddPasswordCheck('')
  },

  setPage(page: number) {
    this.page = page
    paginationStore.setPage(page)
  },

  setRows(rows: number) {
    this.rows = rows
    paginationStore.setRows(rows)
  },

  setUserId(userId: number | '') {
    this.userId = userId
  },

  setUserName(userName: string) {
    this.userName = userName
  },

  setName(name: string) {
    this.name = name
  },

  setFetchedList(fetchedList: User[]) {
    this.fetchedList = fetchedList
    paginationStore.setTotalPage(Math.ceil(fetchedList.length / this.rows))
  },

  setList(list: User[], totalCount: number) {
    this.list = list
    paginationStore.setTotalPage(Math.ceil(totalCount / this.rows))
  },

  setDetail(detail: User) {
    this.detail = detail
  },

  setDetailFirstName(firstName: string) {
    this.detail.FirstName = firstName
  },

  setDetailLastName(lastName: string) {
    this.detail.LastName = lastName
  },

  setDetailActive(active: boolean) {
    this.detail.Active = active
  },

  setDetailUserStatus(userStatus: string) {
    this.detail.UserStatus = userStatus
  },

  setAddUserName(userName: string) {
    this.add.UserName = userName
  },

  setAddPassword(password: string) {
    this.add.Password = password
  },

  setAddFirstName(firstName: string) {
    this.add.FirstName = firstName
  },

  setAddLastname(lastName: string) {
    this.add.LastName = lastName
  },

  setAddHospitalId(hospitalId: number | '') {
    this.add.HospitalID = hospitalId
  },

  setAddHospitalName(hospitalName: string) {
    this.add.HospitalName = hospitalName
  },

  setAddRole(role: number) {
    this.add.Role = role
  },

  setAddActive(active: boolean) {
    this.add.Active = active
  },

  setAddPasswordCheck(passwordCheck: string) {
    this.addPasswordCheck = passwordCheck
  },
})

// 전체 목록이나 검색 조건 변경 시 보여줄 데이터 변경
autorun(() => {
  const filteredList = userStore.fetchedList.filter((user) => {
    if (userStore.userId !== '' && user.UserId !== userStore.userId) return false
    if (userStore.userName !== '' && !(user.UserName.toLowerCase().includes(userStore.userName.toLowerCase()))) return false
    if (userStore.name !== '' && !((
      user.FirstName.toLowerCase().includes(userStore.name.toLowerCase())
      || user.LastName.toLowerCase().includes(userStore.name.toLowerCase())
    ))) return false

    return true
  })

  // 페이지네이션 컴포넌트에 데이터 전달
  runInAction(() => userStore.setList(
    filteredList.slice((userStore.page - 1) * userStore.rows, userStore.page * userStore.rows),
    filteredList.length,
  ))
})

export default userStore
