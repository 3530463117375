import { AxiosRequestConfig } from 'axios'
import { autorun, observable, runInAction } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'
import { orderStatusList, vendorList } from '../../libs/constants'
import paginationStore from '../components/paginationStore'

const patientStore = observable({
  // 검색 정보
  page: 1,
  rows: 10,
  patientId: '',
  patientName: '',
  hospitalName: '',

  // 환자 전체 리스트
  fetchedList: [] as Patient[],

  // 검색 결과 리스트
  list: [] as Patient[],

  // 수정 시 사용하는 데이터
  detail: {
    PatientId: -1,
    PatientUUID: '',
    PatientName: '',
    PatientNumber: '',
    VisitDate: '',
    OperationDate: '',
    Description: '',
    OrderStatus: '',
    Vendor: vendorList[0].key,
    HospitalName: '',
    Confirmed: false,
  } as Patient,

  // 추가 시 사용하는 데이터
  add: {
    PatientNumber: '',
    PatientName: '',
    VisitDate: `${new Date().getFullYear()}-01-01`,
    OperationDate: `${new Date().getFullYear()}-01-01`,
    Vendor: vendorList[0].key,
    Description: '',
    OrderStatus: orderStatusList[0],
    Confirmed: false,
    HospitalID: '',
    HospitalName: '',
  } as {
    PatientNumber: string,
    PatientName: string,
    VisitDate: string,
    OperationDate: string,
    Vendor: number,
    Description: string,
    OrderStatus: string,
    Confirmed: boolean
    HospitalID: number | ''
    HospitalName: string,
  },

  async fetchList() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/patients',
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        const { data } = response

        const modified = data.map((obj: Patient) => ({
          ...obj,
          VisitDate: obj.VisitDate.replace(' 00:00:00 +0000 UTC', ''),
          OperationDate: obj.OperationDate.replace(' 00:00:00 +0000 UTC', ''),
        }))

        this.setFetchedList(modified)
        paginationStore.setQuery(`&patientId=${this.patientId}&patientName=${this.patientName || ''}&hospitalName=${this.hospitalName || ''}`)
      } else if (response.status !== 200) {
        swal('데이터 가져오기에 실패하였습니다.', '', 'error')
      }
    } catch {
      swal('데이터 가져오기에 실패하였습니다.', '', 'error')
    }
  },

  async editData() {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/patient/${this.detail.PatientUUID}`,
      data: {
        PatientNumber: this.detail.PatientNumber,
        PatientName: this.detail.PatientName,
        VisitDate: this.detail.VisitDate,
        OperationDate: this.detail.OperationDate,
        Vendor: this.detail.Vendor,
        Description: this.detail.Description,
        OrderStatus: this.detail.OrderStatus,
        Confirmed: this.detail.Confirmed,
      },
    }

    const response = await authedAxios(config)
    try {
      if (response.status === 200) {
        return true
      }

      swal('수정에 실패하였습니다.', response.data.data, 'error')
    } catch (error) {
      swal('수정에 실패하였습니다.', '', 'error')
    }

    return false
  },

  async deletePatient(patientUuid: string) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `patient/${patientUuid}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      return true
    }

    return false
  },

  async addData() {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/patients',
      data: this.add,
    }

    const response = await authedAxios(config)
    try {
      if (response.status === 200) {
        return true
      }

      swal('추가에 실패하였습니다.', response.data, 'error')
    } catch (error) {
      swal('추가에 실패하였습니다.', '', 'error')
    }

    return false
  },

  initDetail() {
    this.setDetail({
      PatientId: -1,
      PatientUUID: '',
      PatientName: '',
      PatientNumber: '',
      VisitDate: '',
      OperationDate: '',
      Description: '',
      OrderStatus: '',
      Vendor: vendorList[0].key,
      HospitalName: '',
    } as Patient)
  },

  initAdd() {
    this.setAddPatientNumber('')
    this.setAddPatientName('')
    this.setAddVisitDate(`${new Date().getFullYear()}-01-01`)
    this.setAddOperationDate(`${new Date().getFullYear()}-01-01`)
    this.setAddVendor(vendorList[0].key)
    this.setAddDescription('')
    this.setAddOrderStatus('')
    this.setAddConfirmed(false)
    this.setAddHospitalID('')
    this.setAddHospitalName('')
  },

  setPage(page: number) {
    this.page = page
    paginationStore.setPage(page)
  },

  setRows(rows: number) {
    this.rows = rows
    paginationStore.setRows(rows)
  },

  setPatientId(patientId: string) {
    this.patientId = patientId
  },

  setPatientName(patientName: string) {
    this.patientName = patientName
  },

  setHospitalName(hospitalName: string) {
    this.hospitalName = hospitalName
  },

  setFetchedList(fetchedList: Patient[]) {
    this.fetchedList = fetchedList
    paginationStore.setTotalPage(Math.ceil(fetchedList.length / this.rows))
  },

  setList(list: Patient[], totalCount: number) {
    this.list = list
    paginationStore.setTotalPage(Math.ceil(totalCount / this.rows))
  },

  setDetail(detail: Patient) {
    this.detail = detail
  },

  async fetchDetailData(patientUuid: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `patient/${patientUuid}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      const { data } = response

      const modified = {
        ...data,
        VisitDate: data.VisitDate.replace(' 00:00:00 +0000 UTC', ''),
        OperationDate: data.OperationDate.replace(' 00:00:00 +0000 UTC', ''),
      }

      this.setDetail(modified)
    } else {
      window.location.href = '/patient/patients'
    }
  },

  setDetailPatientNumber(patientNumber: string) {
    this.detail.PatientNumber = patientNumber
  },

  setDetailPatientName(patientName: string) {
    this.detail.PatientName = patientName
  },

  setDetailVisitDate(visitDate: string) {
    this.detail.VisitDate = visitDate
  },

  setDetailOperationDate(operationDate: string) {
    this.detail.OperationDate = operationDate
  },

  setDetailDescription(description: string) {
    this.detail.Description = description
  },

  setDetailOrderStatus(orderStatus: string) {
    this.detail.OrderStatus = orderStatus
  },

  setDetailVendor(vendor: number) {
    this.detail.Vendor = vendor
  },

  setDetailConfirmed(confirmed: boolean) {
    this.detail.Confirmed = confirmed
  },

  setAddPatientNumber(patientNumber: string) {
    this.add.PatientNumber = patientNumber
  },

  setAddPatientName(patientName: string) {
    this.add.PatientName = patientName
  },

  setAddVisitDate(visitDate: string) {
    this.add.VisitDate = visitDate
  },

  setAddOperationDate(operationDate: string) {
    this.add.OperationDate = operationDate
  },

  setAddVendor(vendor: number) {
    this.add.Vendor = vendor
  },

  setAddDescription(description: string) {
    this.add.Description = description
  },

  setAddOrderStatus(orderStatus: string) {
    this.add.OrderStatus = orderStatus
  },

  setAddConfirmed(confirmed: boolean) {
    this.add.Confirmed = confirmed
  },

  setAddHospitalID(hospitalId: number | '') {
    this.add.HospitalID = hospitalId
  },

  setAddHospitalName(hospitalName: string) {
    this.add.HospitalName = hospitalName
  },
})

// 전체 목록이나 검색 조건 변경 시 보여줄 데이터 변경
autorun(() => {
  const filteredList = patientStore.fetchedList.filter((patient) => {
    if (patientStore.patientId !== '' && patient.PatientId !== Number(patientStore.patientId)) return false
    if (patientStore.patientName !== '' && !(patient.PatientName.toLowerCase().includes(patientStore.patientName.toLowerCase()))) return false
    if (patientStore.hospitalName !== '' && !(patient.HospitalName.toLowerCase().includes(patientStore.hospitalName.toLowerCase()))) return false

    return true
  })

  // 페이지네이션 컴포넌트에 데이터 전달
  runInAction(() => patientStore.setList(
    filteredList.slice(
      (patientStore.page - 1) * patientStore.rows,
      patientStore.page * patientStore.rows,
    ),
    filteredList.length,
  ))
})

export default patientStore
