import { AxiosRequestConfig } from 'axios'
import { observable } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'

const userModalStore = observable({
  name: '',

  fetchedList: [] as User[],
  list: [] as User[],

  async fetchData() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/users',
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        this.setFetchedList(response.data)
        this.setList(response.data)
      } else {
        swal('유저 목록 불러오기에 실패하였습니다.', response.data.data, 'error')
      }
    } catch (error) {
      swal('유저 목록 불러오기에 실패하였습니다.', '', 'error')
    }
  },

  async grantPermission(patientUuid: string, userId: number) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `permissions/patient/${patientUuid}/user/${userId}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      swal('권한이 부여되었습니다.', '', 'info')
    } else {
      swal('권한 부여에 실패하였습니다.', '', 'error')
    }
  },

  initData() {
    this.setName('')
  },

  setData() {
    this.setList(this.fetchedList.filter(
      (user) => user.UserName.toLowerCase().includes(this.name.toLowerCase()),
    ))
  },

  setName(name: string) {
    this.name = name
  },

  setFetchedList(list: User[]) {
    this.fetchedList = list
  },

  setList(list: User[]) {
    this.list = list
  },
})

export default userModalStore
