import React from 'react'
import { inject, observer } from 'mobx-react'
import { InjectedRootStoreType } from '../stores'

function ProgressBar({ RootStore }: InjectedRootStoreType) {
  const { progressBarStore } = RootStore!

  return (
    progressBarStore.count !== 0 ? (
      <div className="progress-bar">
        <div className="progress-line" />
      </div>
    ) : null
  )
}

export default inject('RootStore')(observer(ProgressBar))
