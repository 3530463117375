import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { InjectedRootStoreType } from '../stores'
import ShowNoDataTr from './ShowNoDataTr'

interface UserModalPropsType extends InjectedRootStoreType {
  setIsModal: (tf: boolean) => void,
  patientUuid: string,
}

function UserModal({ RootStore, setIsModal, patientUuid }: UserModalPropsType) {
  const { userModalStore } = RootStore!

  const [targetUserId, setTargetUserId] = useState(-1)

  // 처음에 데이터 가져오기
  useEffect(() => {
    userModalStore.initData()
    userModalStore.fetchData()
  }, [])

  // 검색 버튼 클릭 시
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    userModalStore.setData()
  }

  return (
    <>
      <div className="user-modal-backdrop" />

      <div className="user-modal">
        <div className="modal-contents">
          <div className="modal-header">
            <button type="button" onClick={() => setIsModal(false)}>&nbsp;</button>
          </div>

          <div className="modal-title">
            권한 부여
          </div>

          <form onSubmit={submitHandler}>
            유저 이름
            <input type="text" value={userModalStore.name} onChange={(e) => userModalStore.setName(e.target.value)} />
            <button type="submit">&nbsp;</button>
          </form>

          <div className={userModalStore.list.length < 8 ? 'table-wrapper' : 'table-wrapper long'}>
            <table>
              <tbody>
                {userModalStore.list.map((user) => (
                  <tr
                    key={user.UserName}
                    className="cursor-pointer"
                    onClick={() => setTargetUserId(user.UserId!)}
                  >
                    <td className={`custom-link ${user.UserId! === targetUserId ? 'selected' : ''}`}>{user.UserName}</td>
                  </tr>
                ))}

                <ShowNoDataTr list={userModalStore.list} colSpan={1} />
              </tbody>
            </table>
          </div>

          <div className="bottom">
            <button type="button" onClick={() => setIsModal(false)}>취소</button>
            <button
              type="submit"
              onClick={() => userModalStore.grantPermission(patientUuid, targetUserId)}
              disabled={targetUserId === -1}
            >
              권한 부여

            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default inject('RootStore')(observer(UserModal))
