export const SURGERYFIT_CURRENT_LOGIN_EMAIL = 'surgeryfit-current-login-email'

export const SURGERYFIT_ACCESS_TOKEN = 'surgeryfit-access-token'
export const SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE = 'surgeryfit-access-token-expire-date'
export const SURGERYFIT_REFRESH_TOKEN = 'surgeryfit-refresh-token'

export const SURGERYFIT_ADMIN_EMAIL = 'surgeryfit-admin-email'

export const orderStatusList = ['EMPTY', 'CT_UPLOAD', 'READY', 'ORDERED']

export const userStatusList = ['created', 'verified', 'reset']
export const userRoleList = [{ key: 1, val: '시스템 관리자' }, /* { key: 2, val: 'role2' }, */ { key: 3, val: '병원' }]

export const vendorList = [
  { key: 10, val: 'Equinoxe Small Reverse Shoulder' },
  { key: 20, val: 'Equinoxe Reverse Shoulder' },
  { key: 30, val: 'Equinoxe Primary system pegged' },
  { key: 40, val: 'Lima SMR shoulder Baseplate' },
  { key: 50, val: 'Biomet Comprehensive Reverse Shoulder System (25mm)' },
  { key: 60, val: 'Biomet Comprehensive convertible Baseplate' },
  { key: 70, val: 'Tornier Perfrom' },
  { key: 80, val: 'Tornier Perfrom Augmnet' },
  { key: 90, val: 'DJO' },
  { key: 100, val: 'Corentect' },
]
