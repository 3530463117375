import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useNavigate, Link } from 'react-router-dom'
import { InjectedRootStoreType } from '../stores'

function Pagination({ RootStore }: InjectedRootStoreType) {
  const numberPerPageGroup = 4

  const navigate = useNavigate()
  const { paginationStore } = RootStore!
  const {
    link, page, rows, query, totalPage,
  } = paginationStore

  // unmout 시 기존 데이터 삭제
  useEffect(() => () => {
    paginationStore.setQuery('')
  }, [])

  // 페이지 유효성 검사
  const [curr, setCurr] = useState(page)
  useEffect(() => {
    if (!page || Number.isNaN(Number(page)) || page < 1) {
      setCurr(1)
    } else {
      setCurr(Number(page))
    }
  }, [page])

  // 페이지 리스트 계산
  const [preset, setPreset] = useState(0)
  useEffect(() => {
    setPreset(Math.floor((page - 1) / numberPerPageGroup))
  }, [page, numberPerPageGroup])

  const to = Math.min((preset + 1) * numberPerPageGroup, totalPage)
  const from = preset * numberPerPageGroup + 1

  const [pageList, setPageList] = useState<number[]>([])
  useEffect(() => {
    const pages: number[] = []

    for (let pageNum = from; pageNum <= to; pageNum += 1) {
      pages.push(pageNum)
    }

    if (pages.length === 0) {
      pages.push(1)
    }

    setPageList(pages)
  }, [from, to])

  return (
    <div className="pagination">
      <button
        type="button"
        className="page-item show-first"
        onClick={() => navigate({
          pathname: link,
          search: `?page=1&rows=${rows}${query}`,
        })}
      >
        &nbsp;
      </button>

      <button
        type="button"
        className={(preset === 0 && pageList.includes(curr)) ? 'page-item show-prev disabled cursor-default' : 'page-item show-prev'}
        onClick={() => setPreset(preset - 1)}
        disabled={preset === 0 && pageList.includes(curr)}
      >
        &nbsp;
      </button>

      {pageList.map((pageNum) => (
        <Link
          key={pageNum}
          className={pageNum === curr ? 'page-item active' : 'page-item'}
          to={`${link}?page=${pageNum}&rows=${rows}${query}`}
        >
          {pageNum}
        </Link>
      ))}

      <button
        type="button"
        className={to === totalPage ? 'page-item show-next disabled cursor-default' : 'page-item show-next'}
        onClick={() => setPreset(preset + 1)}
        disabled={to === totalPage}
      >
        &nbsp;
      </button>

      <button
        type="button"
        className="page-item show-last"
        onClick={() => navigate({
          pathname: link,
          search: `?page=${totalPage}&rows=${rows}${query}`,
        })}
      >
        &nbsp;
      </button>
    </div>
  )
}

export default inject('RootStore')(observer(Pagination))
