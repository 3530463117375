import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { AxiosRequestConfig } from 'axios'
import { authedAxios } from '../libs/axios'
import ShowNoDataTr from './ShowNoDataTr'
import { downloadFile } from '../libs/helpers'

interface Savefile {
  Savename: string,
  UpdatedAt: string,
  S3path: string
}

interface SavefileDownloadModalProps {
  patientUuid: string,
  setIsModal: (tf: boolean) => void
}

function SavefileDownloadModal({ patientUuid, setIsModal }: SavefileDownloadModalProps) {
  const [savefileList, setSavefileList] = useState([] as Savefile[])

  useEffect(() => {
    const fetchData = async () => {
      const config : AxiosRequestConfig = {
        method: 'GET',
        url: `/plannings/patient/${patientUuid}/savefiles`,
      }
      const response = await authedAxios(config)
      if (response.data) {
        setSavefileList(response.data)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <div className="savefile-download-modal-backdrop" />

      <div className="savefile-download-modal">
        <div className="modal-contents">
          <div className="modal-header">
            <button type="button" onClick={() => setIsModal(false)}>&nbsp;</button>
          </div>

          <div className="modal-title">
            세이브파일 다운로드
          </div>

          <table style={{ marginTop: 0 }}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>파일 이름</th>
                <th style={{ width: '18%' }}>최종 수정일</th>
                <th style={{ width: '22%' }}>다운로드</th>
              </tr>
            </thead>
          </table>

          <div className={savefileList.length < 5 ? 'table-wrapper' : 'table-wrapper long'}>
            <table>
              <tbody>
                {savefileList.map((savefile) => (
                  <tr
                    key={savefile.S3path}
                    className="cursor-pointer"
                  >
                    <td className="text-left" style={{ width: '60%' }}>{savefile.Savename}</td>
                    <td style={{ width: '20%' }}>{savefile.UpdatedAt.split('T')[0]}</td>
                    <td style={{ width: '20%' }}>
                      <button type="button" className="user-action download" onClick={() => downloadFile(savefile.S3path)}>&nbsp;</button>
                    </td>
                  </tr>
                ))}

                <ShowNoDataTr list={savefileList} colSpan={3} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default inject('RootStore')(observer(SavefileDownloadModal))
