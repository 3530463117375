import React, { useEffect, useState } from 'react'

const origin = 'Surgeryfit Admin System'

// 한글 문자열 받아서 초성, 중성, 종성으로 분리 후 배열로 반환
// const sepKor = (han: string) => {
//   const res = []

//   // https://gist.github.com/sooop/4958873
//   for (let i = 0; i < han.length; i += 1) {
//     if (han.charCodeAt(i) === 32) {
//       res.push(' ')
//     } else {
//       const temp1 = ((han.charCodeAt(i) - 0xac00) / 28) / 21
//       res.push(String.fromCharCode(temp1 + 0x1100))

//       const temp2 = ((han.charCodeAt(i) - 0xac00) / 28) % 21
//       res.push(String.fromCharCode(temp2 + 0x1161))

//       const temp3 = ((han.charCodeAt(i) - 0xac00)) % 28
//       const jong = String.fromCharCode(temp3 + 0x11A8 - 1)
//       if (jong.charCodeAt(0) !== 4519) {
//         res.push(jong)
//       }
//     }
//   }

//   return res
// }

// const seperated = sepKor(origin)
const seperated = origin.split('')

// 초성용 자음과 일반 자음 매핑
// https://winplz.tistory.com/entry/%ED%95%9C%EA%B8%80-%EC%9E%90%EC%9D%8C-%EB%B9%84%EA%B5%90%EA%B0%99%EC%A7%80%EB%A7%8C-%EB%8B%A4%EB%A5%B8-%EC%9E%90%EC%9D%8C%EB%93%A4
const hanMap: { [key: number]: number } = {
  4352: 12593,
  4353: 12594,
  4354: 12596,
  4355: 12599,
  4356: 12600,
  4357: 12601,
  4358: 12609,
  4359: 12610,
  4360: 12611,
  4361: 12613,
  4362: 12614,
  4363: 12615,
  4364: 12616,
  4365: 12617,
  4366: 12618,
  4367: 12619,
  4368: 12620,
  4369: 12621,
  4370: 12622,
}

function Home() {
  const [text, setText] = useState('')

  useEffect(() => {
    const animation = () => {
      setText('')

      seperated.forEach((letter, idx) => {
        const timeout = idx * 50 + Math.ceil(50 * Math.random())

        const sliced = seperated.slice(0, idx)

        let joined = sliced.join('')

        const charCode = letter.charCodeAt(0)

        if (Object.keys(hanMap).includes(String(charCode))) {
          joined += String.fromCharCode(hanMap[charCode])
        } else {
          joined += letter
        }

        setTimeout(() => {
          setText(joined)
        }, timeout)
      })

      const timer = setTimeout(() => {
        animation()
      }, seperated.length * 100 + 1000)

      return timer
    }

    const timer = animation()

    return () => clearTimeout(timer)
  }, [])

  return (
    <main className="home-page">
      <div className="content">
        <img alt="" />
        <div className="text">{text}</div>
      </div>
    </main>
  )
}

export default Home
