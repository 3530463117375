import React, { ChangeEvent, useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import axios, { AxiosRequestConfig } from 'axios'
import swal from 'sweetalert'
import { InjectedRootStoreType } from '../../stores'
import { authedAxios } from '../../libs/axios'
import { orderStatusList } from '../../libs/constants'
import UserModal from '../../components/UserModal'
import SavefileDownloadModal from '../../components/SavefileDownloadModal'
import { convertVendorcodeToVendorstr } from '../../libs/helpers'
import PermissionRevokeModal from '../../components/PermissionRevokeModal'

const CT_FILE_INPUT_ID = 'ctFileInputId'
const STL_FILE_INPUT_ID = 'stlFileInputId'

function PatientEdit({ RootStore }: InjectedRootStoreType) {
  const { patientStore, progressBarStore } = RootStore!
  const { patientUuid } = useParams()

  // 데이터 가져오기
  useEffect(() => {
    if (patientUuid) {
      patientStore.fetchDetailData(patientUuid)
    } else {
      window.location.href = '/patient/patients'
    }
  }, [patientUuid])

  // CT 업로드 클릭 시
  const onCtUploadClick = () => {
    document.getElementById(CT_FILE_INPUT_ID)?.click()
  }
  const ctUploadHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
    const preConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `/plannings/patient/displayct-upload-url/${patientUuid}`,
    }

    const preResponse = await authedAxios(preConfig)
    const { CTUploadURL } = preResponse.data

    if (CTUploadURL && evt.target.files) {
      progressBarStore.push()
      const response = await axios.put(CTUploadURL, evt.target.files[0])
      progressBarStore.pop()

      if (response.status === 200) {
        swal('업로드에 성공하였습니다.', '', 'info')
      } else {
        swal('업로드에 실패하였습니다.', '', 'error')
      }
    } else {
      swal('업로드 사전 요청에 실패하였습니다.', '', 'error')
    }
  }

  // CT 다운로드 클릭 시
  const downloadHandler = async () => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/plannings/patient/bundlect-download-url/${patientUuid}`,
    }

    try {
      const response = await authedAxios(config)
      const { CTDownloadURL } = response.data

      if (CTDownloadURL) window.open(CTDownloadURL, '_blank', 'noopener,noreferrer')
    } catch (err) {
      if (axios.isAxiosError(err) && err.response && err.response.status === 404) {
        swal('파일이 없습니다.', '', 'error')
      }
    }
  }

  // STL 업로드 클릭 시
  const onStlUploadClick = () => {
    document.getElementById(STL_FILE_INPUT_ID)?.click()
  }
  const stlUploadHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
    const preConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `/plannings/patient/${patientUuid}/savefile-upload-url`,
    }

    const preResponse = await authedAxios(preConfig)
    const { PresignedURL, UpdatedTime } = preResponse.data

    if (PresignedURL && evt.target.files) {
      progressBarStore.push()
      const response = await axios.put(PresignedURL, evt.target.files[0])
      progressBarStore.pop()

      if (response.status === 200) {
        const postConfig: AxiosRequestConfig = {
          method: 'POST',
          url: `/plannings/patient/${patientUuid}/savefile`,
          data: {
            SaveName: 'Original',
            CreatedTime: UpdatedTime,
          },
        }

        const postResponse = await authedAxios(postConfig)
        if (postResponse.status === 200) {
          swal('업로드에 성공하였습니다.', '', 'info')
        } else {
          swal('업로드에 실패하였습니다.', '', 'error')
        }
      } else {
        swal('업로드에 실패하였습니다.', '', 'error')
      }
    } else {
      swal('업로드 사전 요청에 실패하였습니다.', '', 'error')
    }
  }

  // 수정 클릭 시
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await patientStore.editData()
    if (result) {
      swal('수정되었습니다.', '', 'info')
        .then(() => window.location.reload())
    }
  }

  // 삭제 컨펌 모달 관련 state
  const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState(false)
  const [modalChecked, setModalChecked] = useState(false)

  // 삭제 컨펌 모달 닫기/취소
  const closeDeleteModal = () => {
    setModalChecked(false)
    setIsShowDeleteConfirmModal(false)
  }

  // 삭제 버튼 클릭 시
  const onDeleteClick = () => {
    setIsShowDeleteConfirmModal(true)
  }

  // 삭제 컨펌 시
  const onDeleteConfirm = async () => {
    if (patientUuid) {
      const result = await patientStore.deletePatient(patientUuid)
      if (result) {
        setIsShowDeleteConfirmModal(false)

        swal('삭제되었습니다.', '', 'info')
          .then(() => { window.location.href = '/patient/patients' })
      } else {
        swal('삭제에 실패하였습니다.', '', 'error')
      }
    }
  }

  // 권한 부여 모달 관련 state
  const [isShowPermissionGrantModal, setIsShowPermissionGrantModal] = useState(false)

  // 권한 해제 모달 관련 state
  const [isShowPermissionRevokeModal, setIsShowPermissionRevokeModal] = useState(false)

  // 세이브파일 다운로드 모달 관련 state
  const [isShowSavefileDownloadModal, setIsShowSavefileDownloadModal] = useState(false)

  return (
    <main className="patient-edit-page">
      <h1>Patient / Patient Edit</h1>

      <form onSubmit={submitHandler}>
        <div className="form-labels">
          <div className="color-red" style={{ marginLeft: 60 }}>* 표시는 필수항목입니다.</div>

          <label htmlFor="patientId">
            <span>
              PatientId
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="patientId"
              className="read-only"
              value={patientStore.detail.PatientId}
              readOnly
              required
            />
          </label>

          <label htmlFor="patientUUID">
            <span>
              PatientUUID
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="patientUUID"
              className="read-only"
              value={patientStore.detail.PatientUUID}
              readOnly
              required
            />
          </label>

          <label htmlFor="patientNumber">
            <span>
              PatientNumber
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="patientNumber"
              value={patientStore.detail.PatientNumber}
              onChange={(e) => patientStore.setDetailPatientNumber(e.target.value)}
              required
            />
          </label>

          <label htmlFor="patientName">
            <span>
              PatientName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="patientName"
              value={patientStore.detail.PatientName}
              onChange={(e) => patientStore.setDetailPatientName(e.target.value)}
              required
            />
          </label>

          <label htmlFor="visitDate">
            <span>
              VisitDate
              <span className="color-red">* </span>
            </span>

            <input
              type="date"
              id="visitDate"
              value={patientStore.detail.VisitDate}
              onChange={(e) => patientStore.setDetailVisitDate(e.target.value)}
              required
            />
          </label>

          <label htmlFor="operationDate">
            <span>
              OperationDate
              <span className="color-red">* </span>
            </span>

            <input
              type="date"
              id="operationDate"
              value={patientStore.detail.OperationDate}
              onChange={(e) => patientStore.setDetailOperationDate(e.target.value)}
              required
            />
          </label>

          <label htmlFor="description">
            <span>
              Description
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="description"
              value={patientStore.detail.Description}
              onChange={(e) => patientStore.setDetailDescription(e.target.value)}
              required
            />
          </label>

          <label htmlFor="orderStatus">
            <span>
              OrderStatus
              <span className="color-red">* </span>
            </span>

            <select
              id="orderStatus"
              value={patientStore.detail.OrderStatus}
              onChange={(e) => patientStore.setDetailOrderStatus(e.target.value)}
            >
              {orderStatusList.map((value) => (
                <option key={value}>{value}</option>
              ))}
            </select>
          </label>

          <label htmlFor="vendor">
            <span>
              Vendor
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="vendor"
              className="read-only"
              value={convertVendorcodeToVendorstr(patientStore.detail.Vendor)}
              readOnly
              required
            />
          </label>

          <label htmlFor="hospitalName">
            <span>
              HospitalName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="hospitalName"
              className="read-only"
              value={patientStore.detail.HospitalName}
              readOnly
              required
            />
          </label>

          <label htmlFor="confirmed">
            <span>
              Confirmed
              <span className="color-red">* </span>
            </span>

            <input
              type="checkbox"
              id="confirmed"
              checked={patientStore.detail.Confirmed}
              onChange={(e) => patientStore.setDetailConfirmed(e.target.checked)}
            />
          </label>
        </div>

        <div className="user-actions">
          <Link to="/patient/patients" className="user-action go-list">목록</Link>
          <button type="button" className="user-action ct-download" onClick={downloadHandler}>CT 다운로드</button>
          <button type="button" className="user-action ct-upload" onClick={onCtUploadClick}>CT 업로드</button>
          <button type="button" className="user-action stl-upload" onClick={onStlUploadClick}>STL 업로드</button>
          <button type="button" className="user-action savefile-download" onClick={() => setIsShowSavefileDownloadModal(true)}>세이브파일 다운로드</button>
          <button type="button" className="user-action permission-grant" onClick={() => setIsShowPermissionGrantModal(true)}>권한 부여</button>
          <button type="button" className="user-action permission-revoke" onClick={() => setIsShowPermissionRevokeModal(true)}>권한 해제</button>
          <button type="submit" className="user-action save">수정</button>
          <button type="button" className="user-action delete" onClick={onDeleteClick}>삭제</button>
          <input type="file" id={CT_FILE_INPUT_ID} style={{ display: 'none' }} accept=".zip" onChange={ctUploadHandler} />
          <input type="file" id={STL_FILE_INPUT_ID} style={{ display: 'none' }} accept=".zip" onChange={stlUploadHandler} />
        </div>
      </form>

      {isShowDeleteConfirmModal && (
      <>
        <div className="confirm-backdrop" />

        <div className="confirm-modal">
          <div className="modal-contents">
            <div className="close text-right">
              <button type="button" onClick={() => closeDeleteModal()}>&nbsp;</button>
            </div>

            <h1>
              {`정말 ${patientStore.detail.PatientName} 환자를 삭제하시겠습니까?`}
            </h1>

            <label htmlFor="confirm">
              <input
                id="confirm"
                type="checkbox"
                value={String(modalChecked)}
                onChange={(e) => setModalChecked(e.target.checked)}
              />
              <span>&nbsp;&nbsp;동작을 확인하였습니다.</span>
            </label>

            <div className="buttons text-right">
              <button type="button" className="close-btn" onClick={() => closeDeleteModal()}>취소</button>

              <button
                type="button"
                className={`confirm-action delete ${modalChecked ? '' : 'disabled'}`}
                onClick={onDeleteConfirm}
                disabled={!modalChecked}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      </>
      )}

      {isShowPermissionGrantModal && (
        <UserModal setIsModal={setIsShowPermissionGrantModal} patientUuid={patientUuid!} />
      )}

      {isShowPermissionRevokeModal && (
        <PermissionRevokeModal
          setIsModal={setIsShowPermissionRevokeModal}
          patientUuid={patientUuid!}
        />
      )}

      {isShowSavefileDownloadModal && (
        <SavefileDownloadModal
          patientUuid={patientUuid!}
          setIsModal={setIsShowSavefileDownloadModal}
        />
      )}
    </main>
  )
}

export default inject('RootStore')(observer(PatientEdit))
