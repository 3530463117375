import progressBarStore from './components/progressBarStore'
import paginationStore from './components/paginationStore'
import hospitalStore from './pages/hospitalStore'
import userStore from './pages/userStore'
import patientStore from './pages/patientStore'
import userModalStore from './components/userModalStore'
import hospitalModalStore from './components/hospitalModalStore'
import permissionRevokeModalStore from './components/permissionRevokeModalStore'

const RootStore = {
  progressBarStore,
  paginationStore,
  hospitalStore,
  userStore,
  patientStore,
  userModalStore,
  hospitalModalStore,
  permissionRevokeModalStore,
}

type TRootStore = typeof RootStore

interface IRootStore extends TRootStore {}

export interface InjectedRootStoreType {
  RootStore?: IRootStore,
}

export default RootStore
