import { AxiosRequestConfig } from 'axios'
import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import { publicAxios } from '../libs/axios'
import { SURGERYFIT_ADMIN_EMAIL, SURGERYFIT_CURRENT_LOGIN_EMAIL, SURGERYFIT_REFRESH_TOKEN } from '../libs/constants'
import { setToken } from '../libs/helpers'

function Login() {
  // 로그인 이미 된 경우 Home 화면으로 보냄
  useEffect(() => {
    const refreshToken = sessionStorage.getItem(SURGERYFIT_REFRESH_TOKEN)
    if (refreshToken) {
      const onAuthorized = async () => {
        const config: AxiosRequestConfig = {
          method: 'POST',
          url: '/refresh',
          data: { RefreshToken: refreshToken },
        }

        const response = await publicAxios(config)
        try {
          if (response.data.access_token && response.data.refresh_token) {
            setToken(response.data.access_token, response.data.refresh_token)

            document.location.href = '/home'
          }
        } catch (error) {
          swal('자동 로그인에 실패하였습니다.', '', 'error')
        }
      }

      onAuthorized()
    }
  }, [])

  // state
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSaveEmail, setIsSaveEmail] = useState(false)

  // 저장된 이메일이 존재하는 경우
  useEffect(() => {
    const savedEmail = localStorage.getItem(SURGERYFIT_ADMIN_EMAIL)
    if (savedEmail) {
      setEmail(savedEmail)
      setIsSaveEmail(true)
    }
  }, [])

  // 로그인 버튼 클릭 시
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/login',
      data: {
        Username: email,
        Password: password,
      },
    }

    const response = await publicAxios(config)
    try {
      if (response.data.access_token && response.data.refresh_token) {
        // 이메일 저장 여부 처리
        if (isSaveEmail) {
          localStorage.setItem(SURGERYFIT_ADMIN_EMAIL, email)
        } else {
          localStorage.removeItem(SURGERYFIT_ADMIN_EMAIL)
        }

        sessionStorage.setItem(SURGERYFIT_CURRENT_LOGIN_EMAIL, email)
        setToken(response.data.access_token, response.data.refresh_token)

        document.location.href = '/home'
      } else {
        swal('로그인에 실패하였습니다.', '입력값을 확인해주세요.', 'error')
      }
    } catch (error) {
      swal('로그인에 실패하였습니다.', '', 'error')
    }
  }

  return (
    <main className="login-page">
      <div className="login-back">
        <form className="login-form" onSubmit={submitHandler}>
          <h1>로그인</h1>

          <label htmlFor="email">
            <span>이메일</span>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          <label htmlFor="password">
            <span>비밀번호</span>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </label>

          <label htmlFor="isSaveEmail" className="save-email">
            <input
              type="checkbox"
              id="isSaveEmail"
              checked={isSaveEmail}
              onChange={(e) => setIsSaveEmail(e.target.checked)}
            />
            &nbsp; 이메일 저장
          </label>

          <button type="submit">로그인</button>
        </form>
      </div>
    </main>
  )
}

export default Login
