import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { AxiosRequestConfig } from 'axios'
import { InjectedRootStoreType } from '../../stores'
import { userStatusList } from '../../libs/constants'
import { authedAxios } from '../../libs/axios'
import { convertRolecodeToRolestr } from '../../libs/helpers'

function UserEdit({ RootStore }: InjectedRootStoreType) {
  const { userStore } = RootStore!
  const { userId } = useParams()

  // 데이터 가져오기
  useEffect(() => {
    if (userId && !Number.isNaN(Number(userId))) {
      userStore.fetchDetailData(Number(userId))
    } else {
      window.location.href = '/user/users'
    }
  }, [userId])

  // 수정 클릭 시
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await userStore.editData()
    if (result) {
      swal('수정되었습니다.', '', 'info').then(() => window.location.reload())
    }
  }

  // 삭제 컨펌 모달 관련 state
  const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState(false)
  const [modalChecked, setModalChecked] = useState(false)

  // 삭제 컨펌 모달 닫기/취소
  const closeDeleteConfirmModal = () => {
    setModalChecked(false)
    setIsShowDeleteConfirmModal(false)
  }

  // 삭제 버튼 클릭 시
  const onDeleteClick = () => {
    setIsShowDeleteConfirmModal(true)
  }

  // 삭제 컨펌 시
  const onDeleteConfirm = async () => {
    if (userId) {
      const result = await userStore.deleteUser(Number(userId))

      setIsShowDeleteConfirmModal(false)

      if (result) {
        swal('삭제되었습니다.', '', 'info').then(() => {
          window.location.href = '/user/users'
        })
      } else {
        swal('삭제에 실패하였습니다.', '', 'error')
      }
    }
  }

  // 비밀번호 변경 모달 관련 state
  const [isShowPasswordChangeModal, setIsShowPasswordChangeModal] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [passwordCheck, setPasswordCheck] = useState('')
  const [isPasswordChangePossible, setIsPasswordChangePossible] = useState(false)

  // 비밀번호 일치 시 변경 버튼 활성화
  useEffect(() => {
    if (newPassword.length !== 0 && newPassword === passwordCheck) {
      setIsPasswordChangePossible(true)
    } else {
      setIsPasswordChangePossible(false)
    }
  }, [newPassword, passwordCheck])

  // 비밀번호 변경 모달 닫기/취소
  const closePasswordChangeModal = () => {
    setNewPassword('')
    setPasswordCheck('')
    setIsShowPasswordChangeModal(false)
  }

  // 비밀번호 변경 버튼 클릭 시
  const onPasswordChangeClick = () => {
    setIsShowPasswordChangeModal(true)
  }

  // 비밀번호 변경 시
  const onPasswordChange = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/user/${userId}/password`,
      data: {
        NewPassword: newPassword,
      },
    }

    const response = await authedAxios(config)

    closePasswordChangeModal()

    if (response.status === 200) {
      swal('비밀번호가 변경되었습니다.', '', 'info')
    } else {
      swal('비밀번호 변경에 실패하였습니다.', '', 'error')
    }
  }

  return (
    <main className="user-edit-page">
      <h1>User / User Edit</h1>

      <form onSubmit={submitHandler}>
        <div className="form-labels">
          <div className="color-red" style={{ marginLeft: 60 }}>
            * 표시는 필수항목입니다.
          </div>

          <label htmlFor="userId">
            <span>
              UserId
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="userId"
              className="read-only"
              value={userStore.detail.UserId}
              readOnly
              required
            />
          </label>

          <label htmlFor="userName">
            <span>
              UserName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="userName"
              className="read-only"
              value={userStore.detail.UserName}
              readOnly
              required
            />
          </label>

          <label htmlFor="firstName">
            <span>
              FirstName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="firstName"
              value={userStore.detail.FirstName}
              onChange={(e) => userStore.setDetailFirstName(e.target.value)}
              required
            />
          </label>

          <label htmlFor="lastName">
            <span>
              LastName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="lastName"
              value={userStore.detail.LastName}
              onChange={(e) => userStore.setDetailLastName(e.target.value)}
              required
            />
          </label>

          <label htmlFor="role">
            <span>
              Role
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="role"
              className="read-only"
              value={convertRolecodeToRolestr(userStore.detail.Role)}
              readOnly
              required
            />
          </label>

          <label htmlFor="hospitalName">
            <span>
              HospitalName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="hospitalName"
              className="read-only"
              value={userStore.detail.HospitalName || ''}
              readOnly
              required
            />
          </label>

          <label htmlFor="userStatus">
            <span>
              UserStatus
              <span className="color-red">* </span>
            </span>

            <select
              id="userStatus"
              value={userStore.detail.UserStatus}
              onChange={(e) => userStore.setDetailUserStatus(e.target.value)}
            >
              {userStatusList.map((value) => (
                <option key={value}>{value}</option>
              ))}
            </select>
          </label>

          <label htmlFor="active">
            <span>
              Active
              <span className="color-red">* </span>
            </span>

            <input
              type="checkbox"
              id="active"
              checked={userStore.detail.Active}
              onChange={(e) => userStore.setDetailActive(e.target.checked)}
            />
          </label>
        </div>

        <div className="user-actions">
          <Link to="/user/users" className="user-action go-list">
            목록
          </Link>
          <button
            type="button"
            className="user-action password-change"
            onClick={onPasswordChangeClick}
          >
            비밀번호 변경
          </button>
          <button type="submit" className="user-action save">
            수정
          </button>
          <button
            type="button"
            className="user-action delete"
            onClick={onDeleteClick}
          >
            삭제
          </button>
        </div>
      </form>

      {isShowDeleteConfirmModal && (
        <>
          <div className="confirm-backdrop" />

          <div className="confirm-modal">
            <div className="modal-contents">
              <div className="close text-right">
                <button type="button" onClick={() => closeDeleteConfirmModal()}>
                  &nbsp;
                </button>
              </div>

              <h1>
                {`정말 ${userStore.detail.UserName} 환자를 삭제하시겠습니까?`}
              </h1>

              <label htmlFor="confirm">
                <input
                  id="confirm"
                  type="checkbox"
                  value={String(modalChecked)}
                  onChange={(e) => setModalChecked(e.target.checked)}
                />
                <span>&nbsp;&nbsp;동작을 확인하였습니다.</span>
              </label>

              <div className="buttons text-right">
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => closeDeleteConfirmModal()}
                >
                  취소
                </button>

                <button
                  type="button"
                  className={`confirm-action delete ${
                    modalChecked ? '' : 'disabled'
                  }`}
                  onClick={onDeleteConfirm}
                  disabled={!modalChecked}
                >
                  삭제
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {isShowPasswordChangeModal && (
        <div className="add-modal">
          <div className="modal">
            <div className="close">
              <button type="button" onClick={closePasswordChangeModal}>
                &nbsp;
              </button>
            </div>

            <div className="head">비밀번호 변경</div>

            <form onSubmit={onPasswordChange}>
              <div className="form-labels">
                <div className="red">* 표시는 필수항목입니다.</div>

                <label htmlFor="newPassword">
                  <span>
                    NewPassword
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </label>

                <label htmlFor="passwordCheck">
                  <span>
                    PasswordCheck
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="password"
                    id="passwordCheck"
                    value={passwordCheck}
                    onChange={(e) => setPasswordCheck(e.target.value)}
                    style={{
                      backgroundColor: isPasswordChangePossible
                        ? '#dbeee1'
                        : '#f9b5bc',
                    }}
                    required
                  />
                </label>
              </div>

              <div className="bottom">
                <button type="button" onClick={closePasswordChangeModal}>
                  취소
                </button>
                <button type="submit" disabled={!isPasswordChangePossible}>
                  변경
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </main>
  )
}

export default inject('RootStore')(observer(UserEdit))
