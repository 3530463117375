import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { InjectedRootStoreType } from '../stores'
import ShowNoDataTr from './ShowNoDataTr'

interface PermissionRevokeModalPropsType extends InjectedRootStoreType {
  setIsModal: (tf: boolean) => void;
  patientUuid: string;
}

function PermissionRevokeModal({
  RootStore,
  setIsModal,
  patientUuid,
}: PermissionRevokeModalPropsType) {
  const { permissionRevokeModalStore } = RootStore!

  // 처음에 데이터 가져오기
  useEffect(() => {
    permissionRevokeModalStore.fetchData(patientUuid)
  }, [patientUuid])

  // 모달 닫을 때
  const closeModal = () => {
    permissionRevokeModalStore.initSelected()
    setIsModal(false)
  }

  return (
    <>
      <div className="permission-revoke-modal-backdrop" />

      <div className="permission-revoke-modal">
        <div className="modal-contents">
          <div className="modal-header">
            <button type="button" onClick={() => closeModal()}>&nbsp;</button>
          </div>

          <div className="modal-title">
            권한 해제
          </div>

          <div className="selected-container">
            {permissionRevokeModalStore.list
              .filter(({ UserId }) => permissionRevokeModalStore.selected.includes(UserId!))
              .map(({ UserId, UserName }) => (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={UserId}
                  className="selected-item"
                  onClick={() => permissionRevokeModalStore.toggleSelect(UserId!)}
                >
                  {UserName}

                </div>
              ))}
          </div>

          <div className={permissionRevokeModalStore.list.length < 8 ? 'table-wrapper' : 'table-wrapper long'}>
            <table>
              <tbody>
                {permissionRevokeModalStore.list.map(({ UserId, UserName }) => (
                  <tr
                    id={`${UserId}`}
                    key={UserId}
                    className="cursor-pointer"
                    onClick={() => permissionRevokeModalStore.toggleSelect(UserId!)}
                  >
                    <td className={`custom-link ${permissionRevokeModalStore.selected.includes(UserId!) ? 'selected' : ''}`}>{UserName}</td>
                  </tr>
                ))}

                <ShowNoDataTr list={permissionRevokeModalStore.list} colSpan={1} />
              </tbody>
            </table>
          </div>

          <div className="bottom">
            <button type="button" onClick={() => closeModal()}>취소</button>
            <button
              type="submit"
              onClick={() => permissionRevokeModalStore.revokePermission(patientUuid)}
              disabled={permissionRevokeModalStore.selected.length === 0}
            >
              권한 해제

            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default inject('RootStore')(observer(PermissionRevokeModal))
