import { AxiosRequestConfig } from 'axios'
import { autorun, observable, runInAction } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'
import paginationStore from '../components/paginationStore'

const hospitalStore = observable({
  // 검색 정보
  page: 1,
  rows: 10,
  hospitalId: '',
  hospitalName: '',

  // 병원 전체 리스트
  fetchedList: [] as Hospital[],

  // 검색 결과 리스트
  list: [] as Hospital[],

  // 수정 시 사용하는 데이터
  detail: {
    hospitalId: -1,
    hospitalName: '',
    address: '',
    users: [],
  } as Hospital,

  // 추가 시 사용하는 데이터
  add: {
    hospitalName: '',
    address: '',
  } as {
    hospitalName: string,
    address: string,
  },

  async fetchList() {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/hospitals',
    }

    const response = await authedAxios(config)
    try {
      if (response.data) {
        const { data } = response

        const modified = data.map((obj: {
          hospital_id: string, hospital_name: string, address: string
        }) => ({
          ...obj,
          hospitalId: obj.hospital_id,
          hospitalName: obj.hospital_name,
        }))

        this.setFetchedList(modified)
        paginationStore.setQuery(`&hospitalId=${this.hospitalId}&hospitalName=${this.hospitalName || ''}`)
      } else if (response.status !== 200) {
        swal('데이터 가져오기에 실패하였습니다.', '', 'error')
      }
    } catch {
      swal('데이터 가져오기에 실패하였습니다.', '', 'error')
    }
  },

  async editData() {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      // url: `/hospital/${this.detail.HospitalUUID}`,
      data: {
        // HospitalNumber: this.detail.HospitalNumber,
        // HospitalName: this.detail.HospitalName,
        // VisitDate: this.detail.VisitDate,
        // OperationDate: this.detail.OperationDate,
        // Vendor: this.detail.Vendor,
        // Description: this.detail.Description,
        // OrderStatus: this.detail.OrderStatus,
        // Confirmed: this.detail.Confirmed,
      },
    }

    const response = await authedAxios(config)
    try {
      if (response.status === 200) {
        return true
      }

      swal('수정에 실패하였습니다.', response.data.data, 'error')
    } catch (error) {
      swal('수정에 실패하였습니다.', '', 'error')
    }

    return false
  },

  async deleteHospital(hospitalUuid: string) {
    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `hospitals/${hospitalUuid}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      return true
    }

    return false
  },

  async addData() {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/hospitals',
      data: {
        hospital_name: this.add.hospitalName,
        address: this.add.address,
      },
    }

    const response = await authedAxios(config)
    try {
      if (response.status === 200) {
        return true
      }

      swal('추가에 실패하였습니다.', response.data, 'error')
    } catch (error) {
      swal('추가에 실패하였습니다.', '', 'error')
    }

    return false
  },

  initDetail() {
    this.setDetail({
      hospitalId: -1,
      hospitalName: '',
      address: '',
    } as Hospital)
  },

  initAdd() {
    this.setAddHospitalName('')
    this.setAddAddress('')
  },

  setPage(page: number) {
    this.page = page
    paginationStore.setPage(page)
  },

  setRows(rows: number) {
    this.rows = rows
    paginationStore.setRows(rows)
  },

  setHospitalId(hospitalId: string) {
    this.hospitalId = hospitalId
  },

  setHospitalName(hospitalName: string) {
    this.hospitalName = hospitalName
  },

  setFetchedList(fetchedList: Hospital[]) {
    this.fetchedList = fetchedList
    paginationStore.setTotalPage(Math.ceil(fetchedList.length / this.rows))
  },

  setList(list: Hospital[], totalCount: number) {
    this.list = list
    paginationStore.setTotalPage(Math.ceil(totalCount / this.rows))
  },

  setDetail(detail: Hospital) {
    this.detail = detail
  },

  async fetchDetailData(hospitalUuid: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `hospitals/${hospitalUuid}`,
    }

    const response = await authedAxios(config)
    if (response.status === 200) {
      const { data } = response

      const modified = {
        ...data,
        hospitalId: data.hospital_id,
        hospitalName: data.hospital_name,
      }

      this.setDetail(modified)
    } else {
      window.location.href = '/hospital/hospitals'
    }
  },

  setDetailHospitalName(hospitalName: string) {
    this.detail.hospitalName = hospitalName
  },

  setDetailAddress(address: string) {
    this.detail.address = address
  },

  setAddHospitalName(hospitalName: string) {
    this.add.hospitalName = hospitalName
  },

  setAddAddress(address: string) {
    this.add.address = address
  },
})

// 전체 목록이나 검색 조건 변경 시 보여줄 데이터 변경
autorun(() => {
  const filteredList = hospitalStore.fetchedList.filter((hospital) => {
    if (hospitalStore.hospitalId !== '' && hospital.hospitalId !== Number(hospitalStore.hospitalId)) return false
    if (hospitalStore.hospitalName !== '' && !(hospital.hospitalName.toLowerCase().includes(hospitalStore.hospitalName.toLowerCase()))) return false

    return true
  })

  // 페이지네이션 컴포넌트에 데이터 전달
  runInAction(() => hospitalStore.setList(
    filteredList.slice(
      (hospitalStore.page - 1) * hospitalStore.rows,
      hospitalStore.page * hospitalStore.rows,
    ),
    filteredList.length,
  ))
})

export default hospitalStore
