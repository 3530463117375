import { observable } from 'mobx'

const paginationStore = observable({
  link: '',
  page: 1,
  rows: 10,
  query: '',
  totalPage: 1,

  setLink(link: string) {
    this.link = link
  },

  setPage(page: number) {
    if (!page || Number.isNaN(Number(page)) || page < 1) {
      this.page = 1
    } else {
      this.page = page
    }
  },

  setRows(rows: number) {
    this.rows = rows || 10
  },

  setQuery(query: string) {
    this.query = query || ''
  },

  setTotalPage(totalPage: number) {
    if (!totalPage || Number.isNaN(Number(totalPage)) || totalPage < 1) {
      this.totalPage = 1
    } else {
      this.totalPage = totalPage
    }
  },
})

export default paginationStore
