import { Provider } from 'mobx-react'
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import ProgressBar from './components/ProgressBar'
import ScrollToTop from './components/ScrollToTop'
import Home from './pages/Home'
import HospitalEdit from './pages/hospital/HospitalEdit'
import HospitalList from './pages/hospital/HospitalList'
import Login from './pages/Login'
import PatientEdit from './pages/patient/PatientEdit'
import PatientList from './pages/patient/PatientList'
import UserEdit from './pages/user/UserEdit'
import UserList from './pages/user/UserList'
import RootStore from './stores'

function App() {
  return (
    <Provider RootStore={RootStore}>
      <BrowserRouter>
        <ScrollToTop />
        <ProgressBar />
        <Header />

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />

          <Route path="/hospital/hospitals" element={<HospitalList />} />
          <Route path="/hospital/:hospitalId/edit" element={<HospitalEdit />} />

          <Route path="/user/users" element={<UserList />} />
          <Route path="/user/:userId/edit" element={<UserEdit />} />

          <Route path="/patient/patients" element={<PatientList />} />
          <Route path="/patient/:patientUuid/edit" element={<PatientEdit />} />
        </Routes>
      </BrowserRouter>
    </Provider>

  )
}

export default App
