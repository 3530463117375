import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Link, useParams } from 'react-router-dom'
import swal from 'sweetalert'
import { InjectedRootStoreType } from '../../stores'
import ShowNoDataTr from '../../components/ShowNoDataTr'

function HospitalEdit({ RootStore }: InjectedRootStoreType) {
  const { hospitalStore } = RootStore!
  const { hospitalId } = useParams()

  // 데이터 가져오기
  useEffect(() => {
    if (hospitalId) {
      hospitalStore.fetchDetailData(hospitalId)
    } else {
      window.location.href = '/hospital/hospitals'
    }
  }, [hospitalId])

  // 수정 클릭 시
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await hospitalStore.editData()
    if (result) {
      swal('수정되었습니다.', '', 'info')
        .then(() => window.location.reload())
    }
  }

  // 삭제 컨펌 모달 관련 state
  const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState(false)
  const [modalChecked, setModalChecked] = useState(false)

  // 삭제 컨펌 모달 닫기/취소
  const closeDeleteModal = () => {
    setModalChecked(false)
    setIsShowDeleteConfirmModal(false)
  }

  // 삭제 버튼 클릭 시
  const onDeleteClick = () => {
    setIsShowDeleteConfirmModal(true)
  }

  // 삭제 컨펌 시
  const onDeleteConfirm = async () => {
    if (hospitalId) {
      const result = await hospitalStore.deleteHospital(hospitalId)
      if (result) {
        setIsShowDeleteConfirmModal(false)

        swal('삭제되었습니다.', '', 'info')
          .then(() => { window.location.href = '/hospital/hospitals' })
      } else {
        swal('삭제에 실패하였습니다.', '', 'error')
      }
    }
  }

  // 유저 목록 모달 관련 state
  const [isShowUserModal, setIsShowUserModal] = useState(false)

  // 유저 목록 버튼 클릭 시
  const onShowUserListClick = () => {
    setIsShowUserModal(true)
  }

  return (
    <main className="hospital-edit-page">
      <h1>Hospital / Hospital Edit</h1>

      <form onSubmit={submitHandler}>
        <div className="form-labels">
          <div className="color-red" style={{ marginLeft: 60 }}>* 표시는 필수항목입니다.</div>

          <label htmlFor="hospitalId">
            <span>
              HospitalId
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="hospitalId"
              className="read-only"
              value={hospitalStore.detail.hospitalId}
              readOnly
              required
            />
          </label>

          <label htmlFor="hospitalName">
            <span>
              HospitalName
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="hospitalName"
              value={hospitalStore.detail.hospitalName}
              onChange={(e) => hospitalStore.setDetailHospitalName(e.target.value)}
              required
            />
          </label>

          <label htmlFor="address">
            <span>
              Address
              <span className="color-red">* </span>
            </span>

            <input
              type="text"
              id="address"
              value={hospitalStore.detail.address}
              onChange={(e) => hospitalStore.setDetailAddress(e.target.value)}
              required
            />
          </label>
        </div>

        <div className="user-actions">
          <Link to="/hospital/hospitals" className="user-action go-list">목록</Link>
          <button type="button" className="user-action button" onClick={onShowUserListClick}>유저 목록</button>
          <button type="submit" className="user-action save" disabled>수정</button>
          <button type="button" className="user-action delete" onClick={onDeleteClick}>삭제</button>
        </div>
      </form>

      {isShowDeleteConfirmModal && (
      <>
        <div className="confirm-backdrop" />

        <div className="confirm-modal">
          <div className="modal-contents">
            <div className="close text-right">
              <button type="button" onClick={() => closeDeleteModal()}>&nbsp;</button>
            </div>

            <h1>
              {`정말 ${hospitalStore.detail.hospitalName} 병원을 삭제하시겠습니까?`}
            </h1>

            <label htmlFor="confirm">
              <input
                id="confirm"
                type="checkbox"
                value={String(modalChecked)}
                onChange={(e) => setModalChecked(e.target.checked)}
              />
              <span>&nbsp;&nbsp;동작을 확인하였습니다.</span>
            </label>

            <div className="buttons text-right">
              <button type="button" className="close-btn" onClick={() => closeDeleteModal()}>취소</button>

              <button
                type="button"
                className={`confirm-action delete ${modalChecked ? '' : 'disabled'}`}
                onClick={onDeleteConfirm}
                disabled={!modalChecked}
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      </>
      )}

      {isShowUserModal && (
        <>
          <div className="user-list-modal-backdrop" />

          <div className="user-list-modal">
            <div className="modal-contents">
              <div className="modal-header">
                <button type="button" onClick={() => setIsShowUserModal(false)}>&nbsp;</button>
              </div>

              <div className="modal-title">
                [
                {hospitalStore.detail.hospitalName}
                ] 유저 목록
              </div>

              <div className={(hospitalStore.detail.users || []).length < 8 ? 'table-wrapper' : 'table-wrapper long'}>
                <table>
                  <tbody>
                    {(hospitalStore.detail.users || []).map((user) => (
                      <tr
                        key={user.UserId}
                        className="cursor-pointer"
                      >
                        <td className="custom-link" style={{ width: '20%' }}>
                          <Link to={`/user/${user.UserId}/edit`}>{user.UserId}</Link>
                        </td>
                        <td>{user.UserName}</td>
                      </tr>
                    ))}

                    <ShowNoDataTr list={(hospitalStore.detail.users || [])} colSpan={2} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </main>
  )
}

export default inject('RootStore')(observer(HospitalEdit))
