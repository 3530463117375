import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { SURGERYFIT_ACCESS_TOKEN, SURGERYFIT_CURRENT_LOGIN_EMAIL } from '../libs/constants'
import { removeToken } from '../libs/helpers'

const menuList: HeaderMenu[] = [
  {
    menuId: 0,
    menuName: 'Hospital',
    link: '/hospital',
    subMenu: [
      {
        menuId: 0,
        menuName: 'Hospitals',
        link: '/hospital/hospitals',
      },
    ],
  },
  {
    menuId: 1,
    menuName: 'User',
    link: '/user',
    subMenu: [
      {
        menuId: 0,
        menuName: 'Users',
        link: '/user/users',
      },
    ],
  },
  {
    menuId: 2,
    menuName: 'Patient',
    link: '/patient',
    subMenu: [
      {
        menuId: 0,
        menuName: 'Patients',
        link: '/patient/patients',
      },
    ],
  },
]

interface MenuComponentParameter {
  menuInfo: HeaderMenu,
  currPath: string,
}

function Menu({ menuInfo, currPath }: MenuComponentParameter) {
  return (
    <div className="menu-wrapper">
      <span className={currPath.startsWith(`${menuInfo.link}/`) ? 'menu-name active' : 'menu-name'}>
        {menuInfo.menuName}
      </span>

      <div className="submenu-wrapper">
        {menuInfo.subMenu.map((submenu) => (
          <NavLink key={submenu.menuId} className="submenu" to={submenu.link}>{submenu.menuName}</NavLink>
        ))}
      </div>
    </div>
  )
}

function Header() {
  // 로그인 안 된 경우 Login 화면으로 보냄
  const { pathname } = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const token = sessionStorage.getItem(SURGERYFIT_ACCESS_TOKEN)
    if (pathname !== '/' && !token) {
      navigate('/')
    }
  }, [pathname])

  // 로그아웃 클릭 시
  const logoutHandler = () => {
    removeToken()

    navigate('/')
  }

  return (
    pathname !== '/' ? (
      <header className="common-header">
        <div className="menu-wrapper logo">
          SAS
        </div>

        {menuList.length >= 1 && (
          <div className="menu-wrapper home">
            <NavLink
              to="/home"
              className={pathname.startsWith('home') ? 'menu-name home active' : 'menu-name home'}
            >
              Home
            </NavLink>
          </div>
        )}

        {menuList.map((menuInfo) => (
          <Menu key={menuInfo.menuId} menuInfo={menuInfo} currPath={pathname} />
        ))}

        <button
          type="button"
          className="cursor-pointer"
          onClick={logoutHandler}
          title={sessionStorage.getItem(SURGERYFIT_CURRENT_LOGIN_EMAIL) || ''}
        >
          로그아웃

        </button>
      </header>
    ) : null
  )
}

export default Header
