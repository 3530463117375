import axios, { AxiosRequestConfig } from 'axios'
import swal from 'sweetalert'
import progressBarStore from '../stores/components/progressBarStore'
import { SURGERYFIT_ACCESS_TOKEN, SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE, SURGERYFIT_REFRESH_TOKEN } from './constants'
import { removeToken, setToken } from './helpers'

const baseURL = process.env.REACT_APP_BACKEND_URL

const publicAxios = axios.create({ baseURL })

publicAxios.interceptors.request.use(
  (configs) => {
    progressBarStore.push()

    return configs
  },

  (error) => {
    swal('네트워크 에러', '', 'error')

    return Promise.reject(error)
  },
)

publicAxios.interceptors.response.use(
  (response) => {
    progressBarStore.pop()

    return response
  },

  (error) => {
    progressBarStore.pop()

    let msg = ''

    try {
      if (error.response.status === 401) {
        msg += '로그인에 실패하였습니다.'
      } else {
        msg += '네트워크 에러'
        if (error.response && error.response.status) {
          msg += `(${error.response.status})`
        }
      }
    } catch {
      msg += '서버와의 통신이 원활하지 않습니다.'
    }

    swal(msg, '', 'error')

    return Promise.reject(error)
  },
)

const authedAxios = axios.create({
  baseURL,
  headers: {
    Token: sessionStorage.getItem(SURGERYFIT_ACCESS_TOKEN) || '',
  },
})

authedAxios.interceptors.request.use(
  async (configs) => {
    progressBarStore.push()

    const accessToken = sessionStorage.getItem(SURGERYFIT_ACCESS_TOKEN)
    const expireDate = Number(sessionStorage.getItem(SURGERYFIT_ACCESS_TOKEN_EXPIRE_DATE))

    if (!accessToken || accessToken === 'undefined') {
      window.location.href = '/'
    } else if (expireDate - Date.now() / 1000 < 10) {
      const refreshToken = sessionStorage.getItem(SURGERYFIT_REFRESH_TOKEN)

      const config: AxiosRequestConfig = {
        method: 'POST',
        url: '/refresh',
        data: {
          RefreshToken: refreshToken,
        },
      }

      const response = await publicAxios(config)
      try {
        if (response.data.access_token && response.data.refresh_token) {
          setToken(response.data.access_token, response.data.refresh_token)
        }
      } catch (error) {
        window.location.href = '/'
      }
    }

    // eslint-disable-next-line no-param-reassign
    configs.headers!.Token = sessionStorage.getItem(SURGERYFIT_ACCESS_TOKEN) || ''

    return configs
  },

  (error) => {
    swal('네트워크 에러', '', 'error')

    return Promise.reject(error)
  },
)

authedAxios.interceptors.response.use(
  (response) => {
    progressBarStore.pop()

    return response
  },

  (error) => {
    progressBarStore.pop()

    if (error.response && [401, 403].includes(error.response.status)) {
      removeToken()

      window.location.href = '/'
    } else if (error.response && error.response.status === 404) {
      // Do nothing
    } else {
      let msg = '네트워크 에러'
      if (error.response && error.response.status) {
        msg += `(${error.response.status})`
      }
      swal(msg, '', 'error')
    }

    return Promise.reject(error)
  },
)

export { publicAxios, authedAxios }
