import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import ShowNoDataTr from './ShowNoDataTr'
import { InjectedRootStoreType } from '../stores'

interface HospitalModalPropsType extends InjectedRootStoreType {
  setIsModal: (tf: boolean) => void,
  targetIdxSetter: (hidx: number) => void,
  targetNameSetter: (h_nm: string) => void,
}

function HospitalModal({
  RootStore, setIsModal, targetIdxSetter, targetNameSetter,
}: HospitalModalPropsType) {
  const { hospitalModalStore } = RootStore!

  // 처음에 데이터 가져오기
  useEffect(() => {
    hospitalModalStore.initData()
    hospitalModalStore.fetchData()
  }, [])

  // 검색 버튼 클릭 시
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    hospitalModalStore.fetchData()
  }

  return (
    <>
      <div className="hospital-modal-backdrop" />

      <div className="hospital-modal">
        <div className="modal-contents">
          <div className="modal-header">
            <button type="button" onClick={() => setIsModal(false)}>&nbsp;</button>
          </div>

          <div className="modal-title">
            병원 검색
          </div>

          <form onSubmit={submitHandler}>
            병원 이름
            <input type="text" value={hospitalModalStore.name} onChange={(e) => hospitalModalStore.setName(e.target.value)} />
            <button type="submit">&nbsp;</button>
          </form>

          <div className={hospitalModalStore.list.length < 8 ? 'table-wrapper' : 'table-wrapper long'}>
            <table>
              <tbody>
                {hospitalModalStore.list.map((hospital) => (
                  <tr
                    key={hospital.hospitalId}
                    className="cursor-pointer"
                    onClick={() => {
                      targetIdxSetter(hospital.hospitalId)
                      targetNameSetter(hospital.hospitalName)
                      setIsModal(false)
                    }}
                  >
                    <td className="custom-link">{hospital.hospitalName}</td>
                  </tr>
                ))}

                <ShowNoDataTr list={hospitalModalStore.list} colSpan={1} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default inject('RootStore')(observer(HospitalModal))
