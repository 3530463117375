import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { observable } from 'mobx'
import swal from 'sweetalert'
import { authedAxios } from '../../libs/axios'

const permissionRevokeModalStore = observable({
  list: [] as User[],
  selected: [] as number[],

  async fetchData(patientUuid: string) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/permissions/patient/${patientUuid}/user`,
    }

    const response = await authedAxios(config)
    try {
      this.setList(response.data)
    } catch {
      swal('유저 목록 불러오기에 실패하였습니다.', '', 'error')
    }
  },

  async revokePermission(patientUuid: string) {
    const tasks: Promise<AxiosResponse>[] = []

    this.selected.forEach((userId) => {
      const config: AxiosRequestConfig = {
        method: 'DELETE',
        url: `/permissions/patient/${patientUuid}/user/${userId}`,
      }

      tasks.push(authedAxios(config))
    })

    Promise.allSettled(tasks).then((results) => {
      const failed: number[] = []

      results.forEach((result, idx) => {
        if (result.status === 'rejected') {
          failed.push(this.selected[idx])
        }
      })

      if (failed.length === 0) {
        swal('권한이 해제되었습니다.', '', 'info')
      } else {
        swal('일부 유저에 대해 권한 해제가 실패하였습니다.', '', 'error')
      }

      this.fetchData(patientUuid)
      this.initSelected()
    })
  },

  initSelected() {
    this.selected.length = 0
  },

  setList(list: User[]) {
    this.list = list
  },

  toggleSelect(userId: number) {
    if (this.selected.includes(userId)) {
      this.selected.splice(this.selected.indexOf(userId), 1)
    } else {
      this.selected.push(userId)
    }
  },
})

export default permissionRevokeModalStore
