import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import HospitalModal from '../../components/HospitalModal'
import Pagination from '../../components/Pagination'
import ShowNoDataTr from '../../components/ShowNoDataTr'
import {
  convertRolecodeToRolestr, convertRolestrToRolecode, onBlur, onFocus, parseQueryString,
} from '../../libs/helpers'
import { InjectedRootStoreType } from '../../stores'
import { userRoleList } from '../../libs/constants'

function UserList({ RootStore }: InjectedRootStoreType) {
  const { userStore } = RootStore!
  const location = useLocation()
  const navigate = useNavigate()

  // page, rows 하나라도 없으면 쿼리스트링 붙여서 리로드
  const {
    page, rows, userId, userName, name,
  } = parseQueryString(location)
  useEffect(() => {
    if (!page || !rows) {
      navigate({
        pathname: '/user/users',
        search: '?page=1&rows=10&userId=&userName=&name=',
      })
    }
  }, [page, rows, userId, userName, name])

  // 데이터 가져오기
  useEffect(() => {
    if (page && rows) {
      userStore.setPage(page ? Number(page) : 1)
      userStore.setRows(page ? Number(rows) : 10)
      userStore.setUserId([undefined, ''].includes(userId) ? '' : Number(userId))
      userStore.setUserName(userName || '')
      userStore.setName(name || '')

      userStore.fetchList()
    }
  }, [page, rows, userId, userName, name])

  // 검색 state
  const [userIdInput, setUserIdInput] = useState('')
  const [userNameInput, setUserNameInput] = useState('')
  const [nameInput, setNameInput] = useState('')

  // 검색 버튼 클릭 시
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    navigate({
      pathname: '/user/users',
      search: `?page=1&rows=${rows}&userId=${userIdInput}&userName=${userNameInput}&name=${nameInput}`,
    })
  }

  // 추가 모달 관련 state
  const [isModal, setIsModal] = useState(false)

  // 추가 모달 띄우기
  const showModal = () => {
    userStore.initAdd()

    setIsModal(true)
  }

  // 추가 모달 닫기/취소
  const closeModal = () => {
    setIsModal(false)
  }

  // 모달 추가 버튼 클릭 시
  const addPartner = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await userStore.addData()
    if (result) {
      swal('추가되었습니다.', '', 'info')
      setIsModal(false)
      userStore.fetchList()
    }
  }

  // 병원 모달 관련 state
  const [isHospitalModal, setIsHospitalModal] = useState(false)

  return (
    <main className="user-list-page">
      <h1>User / Users</h1>

      <form onSubmit={submitHandler} className="search-bar">
        <label htmlFor="userIdInput">
          <span>UserId</span>
          <img alt="" />
          <input
            type="text"
            id="userIdInput"
            value={userIdInput}
            onChange={(e) => {
              const { value } = e.target
              if (!Number.isNaN(Number(value))) {
                setUserIdInput(e.target.value)
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <label htmlFor="userNameInput">
          <span>UserName</span>
          <img alt="" />
          <input
            type="text"
            id="userNameInput"
            value={userNameInput}
            onChange={(e) => setUserNameInput(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <label htmlFor="nameInput">
          <span>Name</span>
          <img alt="" />
          <input
            type="text"
            id="nameInput"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <button type="submit">&nbsp;</button>
      </form>

      <div className="user-actions text-right">
        <button type="button" className="user-action add" onClick={showModal}>
          <img alt="" />
          유저 추가
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>UserId</th>
            <th style={{ width: '15%' }}>UserName</th>
            <th style={{ width: '15%' }}>HospitalName</th>
            <th style={{ width: '15%' }}>FirstName</th>
            <th style={{ width: '15%' }}>LastName</th>
            <th style={{ width: '10%' }}>Role</th>
            <th style={{ width: '10%' }}>UserStatus</th>
            <th style={{ width: '10%' }}>Active</th>
          </tr>
        </thead>

        <tbody>
          {userStore.list.map((user) => (
            <tr key={user.UserId}>
              <td>
                <Link
                  to={`/user/${user.UserId}/edit`}
                  className="custom-link"
                >
                  {user.UserId}
                </Link>
              </td>
              <td>{user.UserName}</td>
              <td>{user.HospitalName}</td>
              <td>{user.FirstName}</td>
              <td>{user.LastName}</td>
              <td>{convertRolecodeToRolestr(user.Role)}</td>
              <td>{user.UserStatus}</td>
              <td>{String(user.Active)}</td>
            </tr>
          ))}

          <ShowNoDataTr list={userStore.list} colSpan={8} />
        </tbody>
      </table>

      <Pagination />

      {isModal && (
        <div className="add-modal">
          <div className="modal">
            <div className="close">
              <button type="button" onClick={closeModal}>&nbsp;</button>
            </div>

            <div className="head">
              유저 추가
            </div>

            <form onSubmit={addPartner}>
              <div className="form-labels">
                <div className="red">* 표시는 필수항목입니다.</div>

                <label htmlFor="userName">
                  <span>
                    UserName
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="email"
                    id="userName"
                    value={userStore.add.UserName}
                    onChange={(e) => userStore.setAddUserName(e.target.value)}
                    required
                  />
                </label>

                <label htmlFor="password">
                  <span>
                    Password
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="password"
                    id="password"
                    value={userStore.add.Password}
                    onChange={(e) => userStore.setAddPassword(e.target.value)}
                    required
                  />
                </label>

                <label htmlFor="passwordCheck">
                  <span>
                    PasswordCheck
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="password"
                    id="passwordCheck"
                    value={userStore.addPasswordCheck}
                    onChange={(e) => userStore.setAddPasswordCheck(e.target.value)}
                    style={{
                      backgroundColor: (
                        userStore.addPasswordCheck.length !== 0
                        && userStore.add.Password === userStore.addPasswordCheck
                          ? '#dbeee1'
                          : '#f9b5bc'
                      ),
                    }}
                    required
                  />
                </label>

                <label htmlFor="firstName">
                  <span>
                    FirstName
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="text"
                    id="firstName"
                    value={userStore.add.FirstName}
                    onChange={(e) => userStore.setAddFirstName(e.target.value)}
                    required
                  />
                </label>

                <label htmlFor="lastName">
                  <span>
                    LastName
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="text"
                    id="lastName"
                    value={userStore.add.LastName}
                    onChange={(e) => userStore.setAddLastname(e.target.value)}
                    required
                  />
                </label>

                <label htmlFor="hospital" onFocus={() => setIsHospitalModal(true)}>
                  <span>
                    Hospital
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="text"
                    id="hospital"
                    className="read-only with-search"
                    value={userStore.add.HospitalName}
                    required
                    readOnly
                  />

                  <button type="button" className="search-button">&nbsp;</button>
                </label>

                <label htmlFor="role">
                  <span>
                    Role
                    <span className="color-red">* </span>
                  </span>

                  <select
                    id="role"
                    value={convertRolecodeToRolestr(userStore.add.Role)}
                    onChange={(e) => userStore.setAddRole(convertRolestrToRolecode(e.target.value))}
                  >
                    {userRoleList.map(({ key, val }) => (
                      <option key={key}>{val}</option>
                    ))}
                  </select>
                </label>

                <label htmlFor="active">
                  <span>
                    Active
                    <span className="color-red">* </span>
                  </span>

                  <input
                    type="checkbox"
                    id="active"
                    checked={userStore.add.Active}
                    onChange={(e) => userStore.setAddActive(e.target.checked)}
                  />
                </label>
              </div>

              <div className="bottom">
                <button type="button" onClick={closeModal}>취소</button>
                <button type="submit" disabled={!(userStore.isAddPossible)}>추가</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isHospitalModal && (
        <HospitalModal
          setIsModal={(tf) => setIsHospitalModal(tf)}
          targetIdxSetter={(hospitalId) => userStore.setAddHospitalId(hospitalId)}
          targetNameSetter={(hospitalName) => userStore.setAddHospitalName(hospitalName)}
        />
      )}
    </main>
  )
}

export default inject('RootStore')(observer(UserList))
