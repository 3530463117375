import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import Pagination from '../../components/Pagination'
import ShowNoDataTr from '../../components/ShowNoDataTr'
import { onBlur, onFocus, parseQueryString } from '../../libs/helpers'
import { InjectedRootStoreType } from '../../stores'

function HospitalList({ RootStore }: InjectedRootStoreType) {
  const { hospitalStore } = RootStore!
  const location = useLocation()
  const navigate = useNavigate()

  // page, rows 하나라도 없으면 쿼리스트링 붙여서 리로드
  const {
    page, rows, hospitalId, hospitalName,
  } = parseQueryString(location)
  useEffect(() => {
    if (!page || !rows) {
      navigate({
        pathname: '/hospital/hospitals',
        search: '?page=1&rows=10&hospitalId=&hospitalName=',
      })
    }
  }, [page, rows, hospitalId, hospitalName, hospitalName])

  // 데이터 가져오기
  useEffect(() => {
    if (page && rows) {
      hospitalStore.setPage(page ? Number(page) : 1)
      hospitalStore.setRows(page ? Number(rows) : 10)
      hospitalStore.setHospitalId(hospitalId || '')
      hospitalStore.setHospitalName(hospitalName || '')

      hospitalStore.fetchList()
    }
  }, [page, rows, hospitalId, hospitalName])

  // 검색 state
  const [hospitalIdInput, setHospitalIdInput] = useState('')
  const [hospitalNameInput, setHospitalNameInput] = useState('')

  // 검색 버튼 클릭 시
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    navigate({
      pathname: '/hospital/hospitals',
      search: `?page=1&rows=${rows}&hospitalId=${hospitalIdInput}&hospitalName=${hospitalNameInput}`,
    })
  }

  // 추가 모달 관련 state
  const [isModal, setIsModal] = useState(false)

  // 추가 모달 띄우기
  const showModal = () => {
    hospitalStore.initAdd()

    setIsModal(true)
  }

  // 추가 모달 닫기/취소
  const closeModal = () => {
    setIsModal(false)
  }

  // 모달 추가 버튼 클릭 시
  const addHospital = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const result = await hospitalStore.addData()
    if (result) {
      swal('추가되었습니다.', '', 'info')
      setIsModal(false)
      hospitalStore.fetchList()
    }
  }

  return (
    <main className="hospital-list-page">
      <h1>Hospital / Hospitals</h1>

      <form onSubmit={submitHandler} className="search-bar">
        <label htmlFor="hospitalIdInput">
          <span>HospitalId</span>
          <img alt="" />
          <input
            type="text"
            id="hospitalIdInput"
            value={hospitalIdInput}
            onChange={(e) => {
              const { value } = e.target
              if (!Number.isNaN(Number(value))) {
                setHospitalIdInput(e.target.value)
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <label htmlFor="hospitalNameInput">
          <span>HospitalName</span>
          <img alt="" />
          <input
            type="text"
            id="hospitalNameInput"
            value={hospitalNameInput}
            onChange={(e) => setHospitalNameInput(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </label>

        <button type="submit">&nbsp;</button>
      </form>

      <div className="user-actions text-right">
        <button type="button" className="user-action add" onClick={showModal}>
          <img alt="" />
          병원 추가
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>HospitalId</th>
            <th style={{ width: '30%' }}>HospitalName</th>
            <th style={{ width: '60%' }}>Address</th>
          </tr>
        </thead>

        <tbody>
          {hospitalStore.list.map((hospital) => (
            <tr key={hospital.hospitalId}>
              <td>
                <Link to={`/hospital/${hospital.hospitalId}/edit`} className="custom-link">{hospital.hospitalId}</Link>
              </td>
              <td>{hospital.hospitalName}</td>
              <td>{hospital.address}</td>
            </tr>
          ))}

          <ShowNoDataTr list={hospitalStore.list} colSpan={3} />
        </tbody>
      </table>

      <Pagination />

      {isModal && (
      <div className="add-modal">
        <div className="modal">
          <div className="close">
            <button type="button" onClick={closeModal}>&nbsp;</button>
          </div>

          <div className="head">
            병원 추가
          </div>

          <form onSubmit={addHospital}>
            <div className="form-labels">
              <div className="red">* 표시는 필수항목입니다.</div>

              <label htmlFor="hospitalName">
                <span>
                  HospitalName
                  <span className="color-red">* </span>
                </span>

                <input
                  type="text"
                  id="hospitalName"
                  value={hospitalStore.add.hospitalName}
                  onChange={(e) => hospitalStore.setAddHospitalName(e.target.value)}
                  required
                />
              </label>

              <label htmlFor="address">
                <span>
                  Address
                  <span className="color-red">* </span>
                </span>

                <input
                  type="text"
                  id="address"
                  value={hospitalStore.add.address}
                  onChange={(e) => hospitalStore.setAddAddress(e.target.value)}
                  required
                />
              </label>
            </div>

            <div className="bottom">
              <button type="button" onClick={closeModal}>취소</button>
              <button type="submit">추가</button>
            </div>
          </form>
        </div>
      </div>
      )}
    </main>
  )
}

export default inject('RootStore')(observer(HospitalList))
