import { observable } from 'mobx'

const progressBarStore = observable({
  count: 0,

  push() {
    this.count += 1
  },

  pop() {
    this.count -= 1
  },
})

export default progressBarStore
